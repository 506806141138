//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';
export default {
  name: 'app-empty-permissions-page',
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
    }),
  },
  methods: {
    ...mapActions({
      doSignout: 'auth/doSignout',
    }),
    i18n(code) {
      return i18n(code);
    },
  },

};
